// 传参说明：
// timer.countDown(时间戳或时间, 时间每隔一秒更新一次的回调函数)

// 注： 如果中途想停止倒计时可调用 timer.timerSwitch = false， 立即终止倒计时。在vue中如果离开当前页面，需要在beforeDestroy中调用 timer.timerSwitch = false 关闭倒计时

// 定义一个函数的方式实现倒计时

/**
 * 返回参数解释：
 * @days 天数
 * @hours 小时数
 * @minutes 分钟数
 * @seconds 秒数
 * @isEnd 是否倒计时结束
 */
export function Timer() {
  this.timerSwitch = true
  this.countDown = (endTime, updateTime) => {
    let currentDate = new Date()
    let endDate = new Date(endTime)
    if (currentDate >= endDate) {
      updateTime.call(this, {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
        isEnd: true
      })
    } else {
      let timeRemaining = Math.ceil((endDate - currentDate) / 1000) // 计算剩余的毫秒数
      let days = Math.floor(timeRemaining / 60 / 60 / 24) // 计算剩余的天数
      let hours = Math.floor(timeRemaining / 60 / 60 % 24).toString().padStart(2, 0) // 计算剩余的小时
      let minutes = Math.floor(timeRemaining / 60 % 60).toString().padStart(2, 0) // 计算剩余的分钟
      let seconds = Math.floor(timeRemaining % 60).toString().padStart(2, 0) // 计算剩余的秒数
      let isEnd = false // 是否结束
      updateTime.call(this, {
        days,
        hours,
        minutes,
        seconds,
        isEnd
      })
      if (this.timerSwitch) {
        setTimeout(() => {
          this.countDown(endTime, updateTime)
        }, 1000)
      }
    }
  }
}

// 60秒倒计时
// export function sixtyCountDown() {
//   let timer = new Timer()
//   timer.countDown(new Date().getTime() + 59 * 1000, (timeRemaining) => {
//     if (timeRemaining.isEnd) { // 倒计时结束了
//       console.log(timeRemaining) // {days: 0, hours: '00', minutes: '00', seconds: '00', isEnd: true}
//     } else { // 正在倒计时
//       console.log(timeRemaining) // {days: 1, hours: '02', minutes: '03', seconds: '04', isEnd: false}  可通过isEnd判断是否结束
//     }
//   })
// }

// 日、时、分、秒倒计时
/**
 *
 * @param {*} times 时间戳或时间
 */
// export function countDown(times) {
//   let timer = new Timer()
//   timer.countDown(times, (timeRemaining) => {
//     if (timeRemaining.isEnd) { // 倒计时结束了
//       console.log(timeRemaining) // {days: 0, hours: '00', minutes: '00', seconds: '00', isEnd: true}
//     } else { // 正在倒计时
//       console.log(timeRemaining) // {days: 1, hours: '02', minutes: '03', seconds: '04', isEnd: false}  可通过isEnd判断是否结束
//     }
//   })
// }