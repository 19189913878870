import styles from '@/styles/goodsComponent.module.scss'
import { Rate, Button, message } from 'antd'
import Image from 'next/image'
import { getToken } from '@/utils/auth'
import { localStorageService } from "@/utils/storageService"
import FreeShipping from '@/svgs/freeShipping.svg'
import Discount from '@/svgs/discount.svg'
import { useState } from 'react'
import getConfig from 'next/config';
import Link from 'next/link'
import MyTags from "@/components/MyTags"
import { addToCart } from '@/utils/addTocart'

const { publicRuntimeConfig } = getConfig();
const apiDomain = publicRuntimeConfig.apiDomain;

/**
 * @type 1.CrazyDeal 2.Recommend
 */
interface GoodsDetail {
  type: string,
  productID: string;
  productName: string;
  url: string;
  imgUrl: string;
  promotion: string;
  review: number;
  reviewCount: number;
  price: number;
  promotionPrice: number;
  tagTitle: string;
  tagBgColor: string;
  tagFontColor: string;
  freeShipping: number;
  onSale: number;
  reduced: number;
  isNew: number;
  position?: number,
  location?: string;
  isEmailOnly: any,
  promotionTypeID: string | number;
  [key: string]: any;
}

export default function GoodsComponent(props: GoodsDetail) {
  const [showAdded, setShowAdded] = useState(false)
  const [btnLoading, setBtnLoading] = useState(false)

  const addToCartCallBack = (response: any) => {
    if (response != "error") {
      setShowAdded(true)
      setBtnLoading(false)
    } else {
      setBtnLoading(false)
    }
  }

  const preOrder = async (event: any) => {
    event.stopPropagation()
    setBtnLoading(true)
    const isLogin = getToken() == '1'
    const pid = props.productID
    if (!isLogin) {
      location.href = '/login?r=' + encodeURI('/checkout?p=' + pid + '&t=' + 1);
      return false;
    }
    const formData = new FormData()
    formData.append('pid', pid)
    formData.append('qty', '1')
    try {
      const response = await fetch(`${apiDomain}/viewcart/ajaxaddcart`, {
        method: 'POST',
        body: formData,
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'Accept': '*/*'
        },
        mode: 'cors'
      })

      if (!response.ok) {
        throw new Error('请求出错：' + response.statusText);
      }

      const cartData: any = await response.json()
      setBtnLoading(false)
      var data = JSON.stringify(cartData)
      localStorageService.setItem('cartData', data);
      location.href = '/checkout?p=' + pid + '&t=1';
    } catch (error) {
      setBtnLoading(false)
      message.error("An unknown error occurred!")
    }
  }

  // const price = `${props.promotionPrice || props.price}`.split('.')
  let price = `${Number(props.price).toFixed(2)}`.split('.')
  if (props.promotionPrice && props.promotionPrice != null) {
    price = `${Number(props.promotionPrice).toFixed(2)}`.split('.')
  }
  const styleOfCrazyDeal = {
    priceStyleB: {
      fontSize: '26px'
    },
    priceStyleC: {
      fontSize: '22px'
    },
    priceStyleI: {
      fontSize: '16px'
    }
  }

  const styleOfRecommend = {
    priceStyleB: {
      fontSize: '20px'
    },
    priceStyleC: {
      fontSize: '16px'
    },
    priceStyleI: {
      fontSize: '14px'
    }
  }
  const { type } = props
  const styleVersion = type === 'Recommend' ? styleOfRecommend : styleOfCrazyDeal
  return (
    <div className={`${styles['goods-box']} ${type === 'Recommend' && styles['Recommend']}`}>
      <div className={styles['imgthumb']}>
        <Link
          href={props.url}
          legacyBehavior
        >
          <a className={styles['goodsLink']} rel="noopener noreferrer">
            <Image
              src={props.imgUrl}
              alt={props.productName}
              width={200}
              height={200}
            />
          </a>
        </Link>
        {
          !!(props.inStock && props.inStock == 1 && props.isGroup == 0) &&
          <>
            {
              props.preSales == 1
                ?
                <div className={styles['act_user_sel']}>
                  <Link
                    href={props.url}
                    legacyBehavior
                  >
                    <a rel="noopener noreferrer" style={{ display: 'block', width: '100%', height: '100%' }}>
                    </a>
                  </Link>
                  <div className={styles['sel_add']}>
                    <Button className={styles['pre_order_btn']} onClick={preOrder} loading={btnLoading} >PRE-ORDER</Button>
                  </div>
                </div>
                :
                <div className={styles['act_user_sel']} style={showAdded ? { display: 'block' } : {}}>
                  <Link
                    href={props.url}
                    legacyBehavior
                  >
                    <a rel="noopener noreferrer" style={{ display: 'block', width: '100%', height: '100%' }}>
                    </a>
                  </Link>
                  <div className={styles['sel_add']} style={showAdded ? { display: 'none' } : {}}>
                    <Button className={styles['add_addtocart_btn']} onClick={(event) => { setBtnLoading(true); addToCart(event, { productID: props.productID, qty: 1, location: props.location, position: props.position }, addToCartCallBack) }} loading={btnLoading}>Add to Cart</Button>
                  </div>
                  <div className={styles['added']} style={showAdded ? { display: 'block' } : {}}>
                    <p className={styles['addtip']}>
                      <i className={`iconfont csicon-shopping-cart ${styles['icon-shopping-cart']}`} />
                      <span>Item added to your cart!</span>
                    </p>
                    <Button className={styles['cart_btn']} onClick={(event) => { event.stopPropagation(); window.location.href = "/viewcart" }}>View Cart</Button>
                    <Button className={styles['checkout_btn']} onClick={(event) => { event.stopPropagation(); window.location.href = "/checkout/express" }}>Checkout</Button>
                  </div>

                </div>
            }

          </>
        }
      </div>
      <div className={styles['goods-info']}>
        <Link
          href={props.url}
          legacyBehavior
        >
          <a className={styles['goodsLink']} rel="noopener noreferrer">
            <h3 className={styles['goods-name']} dangerouslySetInnerHTML={{ __html: props.productName }} />
          </a>
        </Link>
        <div className={styles['goods-price']}>
          <div className={styles['price']}>
            <b style={styleVersion.priceStyleB}>${price[0]}<i style={styleVersion.priceStyleC}>.{price.length === 2 ? price[1] : '00'}</i></b>
          </div>
          {
            (props.promotionPrice && props.price > 0)
            &&
            <div className={styles['crossedPrice']}>
              <b style={styleVersion.priceStyleI}>$ {props.price}</b>
            </div>
          }
        </div>

        {
          props.inStock && props.inStock == 1
            ?
            // 有库存
            <>
              {
                <div className={`${styles['goods-start']} ${props.reviewCount - 0 > 0 ? '' : styles['goods-start-hidden']}`}>
                  <Rate style={{ fontSize: '16px', color: '#ffc33d' }} allowHalf disabled value={props.review} />
                  <span className={styles['commitNum']}>({props.reviewCount})</span>
                </div>
              }
              {/* Recommend模块专属标签 */}
              {
                props.type === 'Recommend' &&
                <MyTags {...props} />
              }
              {/* CrazyDeal模块专属标签 */}
              {
                !!(props.type === 'CrazyDeal' && !!(props.freeShipping == 1 || props.promotionTypeID > 0))
                &&
                <div className={styles['coupon-deals']}>
                  <div className={styles['desc']}>{props.freeShipping == 1 ? 'Free Delivery' : 'Exclusive Discount'}</div>
                  <div className={styles['icon']}>
                    {
                      props.freeShipping == 1
                        ? <FreeShipping style={{ fill: '#ffffff' }}></FreeShipping>
                        : <Discount style={{ fill: '#ffffff' }}></Discount>
                    }
                  </div>
                </div>
              }
            </>
            :
            // 无库存时候显示，所有商品
            <div className={styles['notify-box']}>
              <Button className={styles['btn']} href={props.url} type="primary">NOTIFY ME</Button>
            </div>
        }
      </div>
      {
        props.type === 'CrazyDeal' && props.tagTitle
        &&
        <div className={styles['tag']} style={{ backgroundColor: props.tagBgColor, color: props.tagFontColor }}>
          {props.tagTitle}
        </div>
      }
    </div>
  )
}